<template>
  <main class="main">
    <div class="main-container">
      <div class="logo-main-container">
        <div class="logo-container">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/logo-boda.png">
        </div>

        <div class="main-countdown-container">
          <div class="countdown-container">

            <div class="day-container">
              <div class="day-number">
                <span>{{ days }}</span>
              </div>
              <div class="day-description">
                <span>Dias</span>
              </div>
            </div>

            <div class="hour-container">
              <div class="hour-number">
                <span>{{ hours }}</span>
              </div>
              <div class="hour-description">
                <span>Horas</span>
              </div>
            </div>

            <div class="minute-container">
              <div class="minute-number">
                <span>{{ minutes }}</span>
              </div>
              <div class="minute-description">
                <span>Minutos</span>
              </div>
            </div>

            <div class="second-container">
              <div class="second-number">
                <span>{{ seconds }}</span>
              </div>
              <div class="second-description">
                <span>Segundos</span>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="presentation-container">
        <div class="presentation-img-container">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/1.webp" loading="lazy">
          <div class="presentation-gradient"></div>
        </div>
      </div>

    </div>
  </main>

  <router-view />
</template>

<script>
export default {
  name: 'root-app',
  data() {
    return {
      targetDate: new Date('2025-02-01T14:00:00').getTime(), // Replace with your target date and time
      intervalId: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  },
  props: {
  },
  mounted() {
    this.startCountdown();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    startCountdown() {
      this.intervalId = setInterval(() => {
        const now = new Date().getTime();
        const distance = this.targetDate - now;

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; /* Includes padding in width calculation */
}

.logo-main-container {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 75%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-container > img {
  width: 100%;
  height: 100%;
  max-width: 550px;
  max-height: 550px;
}

.main-countdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-bottom: 150px;
  width: 100%;
  height: 25%;
  min-height: 100px;
}

.countdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 80%;
  height: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  font-size: 5em;
}

.countdown-container > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  font-family: 'Merriweather', serif;
  text-transform: uppercase;
  color: #333;
}

.day-container, .hour-container, .minute-container, .second-container {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.day-number, .hour-number, .minute-number, .second-number {
  font-size: 4vw; /* Use viewport width for responsive text size */
  line-height: 1.2; /* Line height for better spacing */
}

.day-description, .hour-description, .minute-description, .second-description {
  font-size: 1vw; /* Smaller text for descriptions */
}

.presentation-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.presentation-img-container {
  position: relative;
  overflow: hidden;
  width: 85%;
  margin-bottom: 30px;
}

.presentation-img-container > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.presentation-gradient {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.9), rgba(188, 188, 188, 0.03));
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.9)), to(rgba(188, 188, 188, 0.03)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(188, 188, 188, 0.03));
  height: 20%;
  z-index: 1;
}

@media (max-width: 700px) {
  .logo-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    height: auto;
  }

  .logo-container > img {
    width: 100%;
    height: 100%;
  }

  .countdown-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    height: 100%;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  .day-number, .hour-number, .minute-number, .second-number {
    font-size: 12vw; /* Increase size for smaller screens */
  }

  .day-description, .hour-description, .minute-description, .second-description {
    font-size: 3vw; /* Increase size for descriptions on smaller screens */
  }

  .logo-container img {
    width: 80%; /* Adjust logo size for smaller screens */
  }

  .presentation-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .presentation-img-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;
  }

  .presentation-img-container > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .presentation-gradient {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0.9), rgba(188, 188, 188, 0.03));
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.9)), to(rgba(188, 188, 188, 0.03)));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(188, 188, 188, 0.03));
    height: 20%;
    z-index: 1;
  }

}


</style>

<template>
  <root/>
</template>

<script>
import root from './components/root.vue'

export default {
  name: 'App',
  components: {
    root
  }
}
</script>

<style>
html {
  overflow-x: hidden;
}


body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background: -o-linear-gradient(top, #ffffff 25%, #f6f0ea 75%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #ffffff), color-stop(75%, #f6f0ea));
  background: linear-gradient(to bottom, #ffffff 25%, #f6f0ea 75%);
  overflow-x: hidden;
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translate3d(0,0,0); /* Sometimes helps with rendering issues on iOS */
  -webkit-backface-visibility: hidden;
}


@media (max-width: 700px) {

  body {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    background: -o-linear-gradient(top, #ffffff 25%, #f6f0ea 75%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #ffffff), color-stop(75%, #f6f0ea));
    background: linear-gradient(to bottom, #ffffff 25%, #f6f0ea 75%);
    font-size: 14px;
    overflow-x: hidden;
    margin: 15px;
    justify-items: center;
    -webkit-transform: translate3d(0,0,0); /* Sometimes helps with rendering issues on iOS */
    -webkit-backface-visibility: hidden;
  }

}

</style>

<template>
  <div class="galeria-main-container">
    <div class="galeria-title-container">
      <h1>GALERIA</h1>
    </div>

    <div class="galeria-photos-container">
      <div class="galeria-photos-first-container">
        <div class="galeria-photos-first-left">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/12-min.webp" loading="lazy">
        </div>
        <div class="galeria-photos-first-right">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/11-min.webp" loading="lazy">
        </div>
      </div>

      <div class="galeria-photos-second-container" v-if="!show">
        <div class="galeria-photos-second-left">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/8-min.webp" loading="lazy">
        </div>
        <div class="galeria-photos-second-right">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/7-min.webp" loading="lazy">
        </div>
      </div>
      <div class="galeria-photos-third-container" v-if="show">
        <div class="galeria-photos-third-left">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/6-min.webp" loading="lazy">
        </div>
        <div class="galeria-photos-third-right">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/3-min.webp" loading="lazy">
        </div>
      </div>

      <div class="galeria-photos-third-container" v-if="!show">
        <div class="galeria-photos-third-left">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/6-min.webp" loading="lazy">
        </div>
        <div class="galeria-photos-third-right">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/3-min.webp" loading="lazy">
        </div>
      </div>
      <div class="galeria-photos-second-container" v-if="show">
        <div class="galeria-photos-second-left">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/8-min.webp" loading="lazy">
        </div>
        <div class="galeria-photos-second-right">
          <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/7-min.webp" loading="lazy">
        </div>
      </div>

    </div>
  </div>

</template>


<script>
export default {
  name: 'galeria-app',
  data() {
    return {
      show: window.innerWidth <= 700
    }
  }
}
</script>

<style>
.galeria-main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.galeria-title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
}

.galeria-title-container > h1 {
  font-family: "Playwrite GB S", cursive;
  font-size: 24px;
  font-style: italic;
  font-weight: 100;
}

.galeria-photos-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

img {
  border-radius: 2px;
}

.galeria-photos-container > div {
  margin-top: 40px;
}

.galeria-photos-first-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.galeria-photos-first-container > div {
  padding: 10px;
}

.galeria-photos-first-left, .galeria-photos-first-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: white 1px solid;
  width: 45%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.galeria-photos-first-left > img, .galeria-photos-first-right > img {
  max-width: 100%;
  max-height: 100%;
}

/*///////////////         SECOND        ///////////////*/
.galeria-photos-second-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.galeria-photos-second-container > div {
  padding: 10px;
}

.galeria-photos-second-left, .galeria-photos-second-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: white 1px solid;
  width: 45%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.galeria-photos-second-left > img, .galeria-photos-second-right > img {
  max-width: 100%;
  max-height: 100%;
}


/*///////////////         THIRD        ///////////////*/
.galeria-photos-third-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.galeria-photos-third-container > div {
  padding: 10px;
}

.galeria-photos-third-left, .galeria-photos-third-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: white 1px solid;
  width: 45%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.galeria-photos-third-left > img, .galeria-photos-third-right > img {
  max-width: 100%;
  max-height: 100%;
}


@media (max-width: 700px) {

  .galeria-photos-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
  }

  .galeria-photos-container > div {
    margin-top: 25px;
  }

  .galeria-photos-first-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .galeria-photos-first-left, .galeria-photos-first-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: white 1px solid;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .galeria-photos-first-right {
    margin-top: 15px;
  }

  .galeria-photos-first-left > img, .galeria-photos-first-right > img {
    max-width: 100%;
    max-height: 100%;
  }

  /*///////////////         SECOND        ///////////////*/
  .galeria-photos-second-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }

  .galeria-photos-second-container > div {
    padding: 10px;
  }

  .galeria-photos-second-left, .galeria-photos-second-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: white 1px solid;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .galeria-photos-second-right {
    margin-top: 15px;
  }

  .galeria-photos-second-left > img, .galeria-photos-second-right > img {
    max-width: 100%;
    max-height: 100%;
  }


}

</style>

<template>
  <div class="invitation-container">
    <div class="invitation-greeting-container">
      <span>Andrea & Juan Pablo</span>
      <label>"Cuando te das cuenta de que quieres pasar el resto de tu vida con alguien, deseas que el resto de tu vida empiece lo antes posible."</label>
    </div>

    <div class="invitation-info-container">
      <span>Estamos muy felices de unir nuestras vidas y queremos sean parte de esta ocasión especial, por ello nos gustaría nos acompañen a celebrar.</span>
      <br/>
      <span>SABADO</span>
      <span>01 FEBRERO 2025</span>
      <a href="data:text/calendar;charset=utf-8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0ADTSTART:20250202T020000Z%0ADTEND:20250202T070000Z%0ASUMMARY:BODA - ANDREA & JUAN%0ADESCRIPTION:Boda de tus mejores amigos!%0ALOCATION:Jardin de Aylinn%0AEND:VEVENT%0AEND:VCALENDAR"
         download="event.ics" v-if="show">
        <button>Agregar al calendario</button>
      </a>
    </div>

    <div class="flower-separator">
      <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/flower.png">
    </div>

    <div class="invitation-parents-container">
      <div class="parents-title-container">
        <h1>Acompañados de nuestros padres</h1>
      </div>
      <div class="parents-container">
        <div class="parents-left-container">
          <span class="parents-wife">Novia</span>
          <div class="left-mother-name-container">
            <span>Lucía Cabanillas</span>
          </div>
          <div class="left-mother-name-container">
            <span>Blanca Jiménez</span>
          </div>
          <div class="left-father-name-container">
            <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6UlEQVR4nO3asQkCMRxG8W8YV3ABK8FB7EUnuB3cwc4F3MDWDURwioiVr8k1AY137wf/NpBHEq64ROrJIsm6MsvMzJCkVOaSmRmM8WEMMAYYA4wBxgBjgDHAGGAMMAYYA4wBxphqjFWSY8NcR2LcG9fefjvGbmQzv56zMWKM4smI16T4ZqT/B3ST5NQwt5HNPBvX3ufPDFP66GplDDAGGAOMAcYAY4AxwBhgDDAGGAOMAcYAY4AxwBhgDDAGGAOMAcYAY4AxwBhgDDAGGAOMAcYAY4AxwBhgDDAGGAOMAYckj8q8/9iT0o8XT7unwPqhdZ4AAAAASUVORK5CYII="
                alt="cross">
            <span>Abel Jiménez</span>
          </div>
        </div>
        <div class="parents-right-container">
          <span class="parents-husb">Novio</span>
          <div class="right-mother-name-container">
            <span>Juana Martínez</span>
          </div>
          <div class="right-father-name-container">
            <span>Jesus Benítez</span>
          </div>
        </div>
      </div>
    </div>

    <div class="invitation-sec-parents-container">
      <div class="parents-title-container">
        <h1>y nuestros padrinos de velación</h1>
      </div>
      <div class="parents-container">
        <div class="parents-left-container">
          <div class="left-mother-name-container">
            <span>Ofelia Gonzalez</span>
          </div>
          <div class="left-father-name-container">
            <span>Alberto Medina</span>
          </div>
        </div>
        <div class="parents-right-container">
          <div class="right-mother-name-container">
            <span>Mireya Zazueta</span>
          </div>
          <div class="right-father-name-container">
            <span>Porfirio Reyes</span>
          </div>
        </div>
      </div>
    </div>

    <div class="galeria-first-container">
      <div>
        <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/2-min.webp" loading="lazy">
        <label>"En cada beso está la promesa de toda una vida."</label>
      </div>
    </div>

    <itinerary/>

    <div class="galeria-second-container">
      <div>
        <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/4-min.webp" loading="lazy">
        <label>"En nuestra historia, el amor es el único idioma que entendemos."</label>
      </div>
    </div>

    <div class="dress-code-container">
      <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/formal-dress-code.webp">
      <span>VESTIMENTA FORMAL</span>
      <div class="dress-code-txt">
        <span>Dama evita el uso de color blanco o verde en honor a la novia</span>
        <span>Caballero de etiqueta con corbata </span>
      </div>
    </div>

    <div class="galeria-third-container">
      <div>
        <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/16-min.webp" loading="lazy">
        <label>"Cuando se ama, no se sabe cómo, ni por qué, ni hasta cuándo."</label>
      </div>
    </div>

    <div class="flower-separator">
      <img src="https://andreajuanpablo.sfo3.digitaloceanspaces.com/flower.png">
    </div>

    <giftTable/>

    <div class="ticket-main-container">
      <div class="ticket-secondary-container">
        <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVR4nO2XTUhUURTHf6ZUKET0IdnCTVKGi4iQksqFSCuLJIkKCaJNEKJE5CpqKRKEQkQu2gTirqJNhJs+rISCtgYFhhUWURRmSfniwhk4zcxz7oxv3hf3B5eZeXPfefd/3z3/ey44HA6HIyK8lDRSJySpeKkV0kDyaMgn5Auwn+SwG5j1S/ZfwDHiTyfw0y/Zr8vnX6CP+HJOxqjHnJPsvarTELCC+FAJDMvYFoHLhVzriHptt4FqoqcauKuW/wlb+90DfJLrk0At0bEeeKIMqbXYfWQLMCX/vQG2EY29vpYxvAUaS90QzWw8jsieW4DPFqvCemdfBYyFbM9dwLxlnhZVolSIS2Q7RjnoLdI5S6q1zEP+SP8RoIpg7fWamqz+cheNh4E5uedOQPZcA9yTmGZJHQ2r+tV1zvNl2nMd8FJizUrsUMv4nWqZlWrPTcC0xDCxTEzCFNKk9pjFJTarpWgDvmbFmJLYoQg5BHyTe14B24FR+f0b6LaIcVL6Zux1qyxRD/hR7hwxrjKoZu+WSnRjkVfU7F70sU1z7ZKKcVX1Ww3cVDEG5ZmBCtkAjEu/BaDHp99ZlTcPgQ5go7SDwCOVD34xzqi3NS7PDkTILpWQH4F9BQIfAD5kHXp0ew+0W5QnM9J/WsawLCGnVKkwAWzGjrXABVn3c9KeAeeBNZYxNqk3OC9jKVqI2bEH1H83gJWET5XFOHyFmFl/qmbiNNFzXFUTL4D6QkL2qvX9DmgmPuyQjdeTQ5/Zh/IK6RFHMt8fyFkkbqwD7me5Z46QjH8PWPp3VFRIZZwp9XOEfJcDTVLoUCXOf0LynYfjTmMQ1W9c8FIrxEt4IzVCHA6Hw0HY/AN8XwJoJc/SDAAAAABJRU5ErkJggg==">
        <h3>Pase:</h3>
        <span>{{ peopleNumber }} {{ peopleNumber > 1 ? 'PERSONAS' : 'PERSONA'}}</span>
      </div>
      <div class="ticket-advise-container">
        <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA60lEQVR4nO3YMWoCURhF4VNLbJMupjV1XEAslMkmdA8ZF6GdYh0XoS5CF6E27sAUaTQ8cMDiz7OScOV+8ECejacY5iKYmZmZXfMBnP44BUJamZA3hDQyIc8IqWVC0ndSDkFEupOzDUI2CFoHISsELYOQBYJmQcgXgkZByBBBgyCkRFA/COlxJ3urQFBLfWdVXoKQtMHk1O5hZ1W+1XdWZXcRkraXrLX6zor2luTOMruxNjA+n3dElcDx4mFPnz8R8wT8BG/2dPeIkG7m76AOQl4zIU3ELIKIOYLqwBTYn88EePjvH2VmZmYI+AXvN4VrGycHwgAAAABJRU5ErkJggg==">
        <span>Nos interesa que disfruten y se diviertan, respetuosamente pedimos que los niños se queden en casa</span>
      </div>
    </div>

    <div class="confirmation-main-container">
      <div class="confirmation-txt-container">
        <span class="confirmation-title">TE PEDIMOS DE FAVOR CONFIRMAR ASISTENCIA</span>
        <span>Haciendo click en la opción correspondiente</span>
      </div>
      <div class="confirmation-buttons-container">
        <a href="https://wa.me/5216673465671/?text=Hola%20con%20gusto%20asistiremos.%0ANombres%3A%20" target="_blank">
          <button>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVR4nO2SuwmAQBAFRzCUiwwNrcPoCrCLzcxs0TYMjM3EUEQ4QQR/eAsi9wqYWd4+CPlrMiDSglfABJSa8AZIfMNlAzcB/s1aaqAF8odrMXcvKoAR6C4k1ZtaFslwIhEfnR9JxOdD9xLRWIt1P+k1p2idRAW+JgViLXgI6pkBrdIq3SW1qBgAAAAASUVORK5CYII=">Si asistiré
          </button>
        </a>

        <a href="https://wa.me/5216673465671/?text=Hola%20para%20informar%20que%20no%20podremos%20asistir.%0ANombres%3A%20" target="_blank">
          <button>
            <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAh0lEQVR4nO2USw6AIAwF5xIlckQ9riw8Dm4wMYZAy8e4cFYs2vcKLYWfr7ICzhDvUo6KDYhAUJq4FBtTbhUB9pRwAN4Qu2hvoTGRVnGNifSKl0xklHiuieFxtkxaEblVPazymoEfJe5mPpFkGmr5J2bxi24TzShKq4llzqXFZPqym76uf97lBItjSzKPDXfjAAAAAElFTkSuQmCC">No asistiré
          </button>
        </a>

      </div>

    </div>

  </div>

  <galeria/>

</template>

<script>
import itinerary from './Itinerary.vue'
import giftTable from './GiftTable.vue'
import galeria from './Galeria.vue'

export default {
  name: 'home-app',
  data() {
    return {
      hashId: null,
      peopleNumber: 0,
      show: window.innerWidth <= 700
    }
  },
  components: {
    itinerary,
    giftTable,
    galeria
  },
  mounted() {
    this.hashId = this.$route.params.hashId;
    this.peopleNumber = this.getNumberOfGuests(this.hashId);
  },
  methods: {
    getNumberOfGuests(paramValue) {
      switch (paramValue) {
        case "a69ydo4da7nch2b79ggfa758q":
          return 1;
        case "6bu3t898vvcn7zoi0m8e3n6mg":
          return 2;
        case "e0jx1351he6djnnc256kcnoac":
          return 3;
        case "xilf391j94h429xhde4onu61l":
          return 4;
        case "456y70blzul4jtbh3okrxwjw6":
          return 5;
        case "q87n74hxjq9jop6e010waqp3b":
          return 6;
        case "8g8q259rysv253hy401psbnbz":
          return 7;
        case "v19wwnv7xn7ep13ig2oq4q0s9":
          return 8;
        case "2s7xpx04xepn9636ygazwna9u":
          return 9;
        case "p6568p9h4etc6vl0f12wky7cu":
          return 10;
        default:
          this.$router.push('/')
          return 0;
      }
    }
  }
}

</script>

<style>
.flower-separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 80px 0;
}

.flower-separator > img {
  width: 285px;
}


.invitation-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.invitation-greeting-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 60px;
}

.invitation-greeting-container > span {
  font-size: 3em;
  font-family: "Edu AU VIC WA NT Pre", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
}

.invitation-greeting-container > label {
  font-size: 1em;
  font-family: "Edu AU VIC WA NT Pre", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.invitation-info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 50%;
  font-size: 1.2em;
}

.invitation-parents-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.invitation-sec-parents-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.parents-title-container > h1 {
  font-family: "Playwrite GB S", cursive;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  margin: 0;
}

.parents-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  font-size: 1.2em;
}

.parents-left-container, .parents-right-container {
  margin-top: 20px;
}

.parents-wife, .parents-husb {
  text-decoration: underline;
}

.left-mother-name-container, .right-mother-name-container {
  margin-top: 15px;
}

.left-father-name-container, .right-father-name-container {
  margin-top: 15px;
}

.left-father-name-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.left-father-name-container > img {
  max-width: 28px;
  max-height: 28px;
}

.galeria-first-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 100px 0;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.galeria-first-container > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.galeria-first-container > div > img {
  width: 600px;
  border-radius: 5px;
}

.galeria-first-container > div > label {
  max-width: 70%;
  font-size: 13px;
  font-family: "Edu AU VIC WA NT Pre", cursive;
}

.galeria-second-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 0;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.galeria-second-container > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.galeria-second-container > div > img {
  width: 800px;
  border-radius: 5px;
  padding-bottom: 5px;
}

.galeria-second-container > div > label {
  max-width: 70%;
  font-size: 13px;
  font-family: "Edu AU VIC WA NT Pre", cursive;
}

.galeria-third-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 100px 0;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.galeria-third-container > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.galeria-third-container > div > img {
  width: 600px;
  border-radius: 5px;
}

.galeria-third-container > div > label {
  max-width: 70%;
  font-size: 13px;
  font-family: "Edu AU VIC WA NT Pre", cursive;
}

.ticket-main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ticket-secondary-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ticket-secondary-container > img {
  width: 50px;
}

.ticket-secondary-container > h3 {
  font-size: 18px;
  margin: 2px;
}

.ticket-secondary-container > span {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.ticket-advise-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  border: #333333 1px solid;
  padding: 15px;
  margin-top: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  max-width: 570px;
}

.ticket-advise-container > img {
  width: 40px;
  margin-bottom: 5px;
}

.dress-code-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 50px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dress-code-container > img {
  width: 110px;
  margin-bottom: 10px;
}

.dress-code-txt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 10px;
}

.dress-code-txt > span {
  margin-top: 10px;
}


.confirmation-main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}

.confirmation-txt-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 25px;
}

.confirmation-title {
  font-size: 18px;
  font-weight: 600;
}

.confirmation-buttons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.confirmation-buttons-container > a {
  text-align: center;
  justify-items: center;
}

.confirmation-buttons-container > a > button {
  width: 130px;
  height: 35px;
  background: #ffffff;
  color: #333333;
  border: none;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.confirmation-buttons-container > a > button:hover {
  background: #cacaca;
  color: #333333;
  scale: calc(1.1);
  cursor: pointer;
}

.confirmation-buttons-container > a > button > img {
  width: 20px;
}


@media (max-width: 700px) {

  .flower-separator {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 20px 0;
  }

  .flower-separator > img {
    width: 185px;
  }

  .invitation-greeting-container {
    margin-bottom: 40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .invitation-greeting-container > span {
    font-size: 2em;
    font-family: "Edu AU VIC WA NT Pre", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: italic;
  }

  .invitation-greeting-container > label {
    max-width: 90%;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-size: 0.8em;
    font-family: "Edu AU VIC WA NT Pre", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  .parents-title-container {
    margin: 0 18px;
  }

  .parents-title-container > h1 {
    font-family: "Playwrite GB S", cursive;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    margin: 0;
  }

  .invitation-info-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 90%;
    font-size: 1.2em;
  }

  .invitation-info-container > span{
    text-align: center;
  }

  .left-father-name-container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .left-father-name-container > img {
    max-height: 25px;
    max-width: 25px;
  }

  .galeria-first-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 50px 0;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .galeria-first-container > div > img {
    width: 80%;
    border-radius: 5px;
  }

  .galeria-second-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 80px;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .galeria-second-container > div > img {
    width: 90%;
    border-radius: 5px;
  }

  .galeria-third-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 50px 0;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .galeria-third-container > div > img {
    width: 90%;
    border-radius: 5px;
  }

  .dress-code-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 50px 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 80%;
  }

  .ticket-advise-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 10px;
    border: none;
    padding: 15px;
    margin-top: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    max-width: 85%;;
  }

  .ticket-advise-container > img {
    width: 30px;
    margin-bottom: 2px;
  }

  .ticket-advise-container > span {
    font-size: 10px;
  }

  .confirmation-buttons-container > a > button:hover {
    background: #cacaca;
    color: #333333;
    scale: calc(1.1);
    cursor: pointer;
  }

}

</style>
